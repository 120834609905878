<template>
  <div
    v-if="channelData.xid"
  >
    <div class="row channel__column">
      <!---poster="https://us-central-1-s3.netdepot.com/brime/ShareX/2021/08/brime_loading.gif" --->
      <vue-plyr ref="plyr" :options="plyrOptions">
      <video
        :id="channel"
        playsinline
        muted
        :data-poster="offline_image"
        poster="https://assets.brimecdn.com/stream_loading.gif"
      /></vue-plyr>
    </div>
    <div class="channelData"><a @click="openStream">
      <b-avatar
        v-if="channelData.owner.xid"
        size="50"
        :src="`https://assets.brimecdn.com/cdn-cgi/image/width=80,quality=100/users/${channelData.owner.xid}/avatar`"
        variant="light-primary"
        class="badge-minimal hide-on-mobile"
      />
      <span
        style="
                vertical-align: top;
                color: white;
                font-size: 16px;
              "
        class="channelName"
      >
        <img
          v-if="channelData.verified"
          src="https://beta.brimelive.com/brime_verified.png"
          style="padding-left: 5px; width: 20px !important;"
          
        >
        {{ channelData.display_name }}</span>
        <b-button
        class="watchButton"
        variant="primary"
        size="sm"
        @click="openStream"
      >
        Watch
      </b-button>
      <span
        class="streamCategory"
      >
        <router-link :to="`/category/${channelData.stream.category.name}`">{{
          channelData.stream.category.name
        }}</router-link>
        &bull;
        <span class="streamTitle">
          {{ channelData.stream.title }}
        </span>
      </span></a>
    </div>
  </div>
</template>

<script>
import {
  VBPopover, BButton, BInputGroup,
  BFormInput, BForm, BAvatar, BModal, VBModal, BCardText, BButtonGroup, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import clipModule from '@/components/clipping/Clip.vue'
import VuePlyr from 'vue-plyr'
import axios from 'axios'
import 'vue-plyr/dist/vue-plyr.css'
import Hls from "hls.js";

/* eslint-disable */

export default {
  directives: {
    'b-popover': VBPopover,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    // BModal,
    // BCardText,
    BInputGroup,
    BFormInput,
    BForm,
    BAvatar,
    BModal,
    clipModule,
    VBModal,
    BCardText,
    BButtonGroup,
    BDropdown,
    BDropdownItem, 
    BDropdownDivider,
    // BTabs,
    // BTab,
    // BRow,
    // BCard,
    // BCol,
    VuePlyr
  },
  props: ['channel'],
  data() {
    return {
      streamURL: '',
      disableLL: false,
      offline_image: '',
      verified: false,
      isChannelOwner: false,
      isSubscribed: false,
      following: true,
      monetized: false,
      live: true,
      followers: 0,
      viewerCount: 0,
      countTerm: 'viewers',
      viewCounter: null,
      clipModalSize: 'lg',
      COUNT_DELETEME: 0,
      textInput: '',
      autoCompPlaceholder: '',
      suggestions: [],
      channelData: {channel: {slug: ''}, channel_owner: {}, stream: {category_name: ''}},
      plyrOptions: {
        invertTime: false,
        toggleInvert: false,
        // ratio: '16:9',
        controls: [
        // this.live ? 'play-large' : '', // The large play button in the center
        // 'play-large': '',
        // 'rewind',
          'play',
          // 'fast-forward',
          // 'progress',
          'mute',
          'volume',
          'current-time',
          'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
        settings: [
          'quality',
          // 'speed',
        ],
        quality: {
          default: '1080p',
          options: [],
          forced: true,
          onChange: (e) => this.changeQuality(e)
        },
      },
    }
  },
  async created() {
    const channelLookup = await this.channelLookupBySlug(this.channel)
    this.channelData = channelLookup
    this.live = this.channelData.stream.live
    // if(this.channelData !== "") {
    //   this.streamURL = `live.brimecdn.com/edge_abr/${this.channelData.channel.legacy_id}`
    // } else {
      this.streamURL = `live.brimecdn.com/live/${this.channelData.xid}`
    // }
    if (this.channelData && !this.live){
    if(this.channelData.offline_image){
        this.offline_image = `https://assets.brimecdn.com/cdn-cgi/image/width=1920,quality=100/brime/channels/${this.channelData.channel.xid}/offline_image?date=${Date.now()}`
      } else {
        this.offline_image = 'https://assets.brimecdn.com/cdn-cgi/image/width=1920,quality=100/brime/channels/channel_offline.png'
    }
    if(!this.live){
      this.offline_image = `https://assets.brimecdn.com/stream_loading.gif`
    }
    }
    // if (this.channelData.channel.status == 'ELITE') {
    // this.monetized = true
    // }
    this.followers = this.channelData.followers

    // if (this.$auth.user) {
    // this.following = await this.userFollowCheck(this.channelData.xid)
    // }
    // if (this.$auth.user) {
    // const subLookup = await this.subCheck(this.channelData.xid)
    // this.isSubscribed = subLookup.subscribed
    // }
    
    //  if (window.brimePlayer.isMediaSourceSupported()){
    //  const sldpPlayer = window.brimePlayer.init({
    //   container: 'sldp-player',
    //   video_element: 'stream-video',
    //   stream_url: `wss://live.brimecdn.com/edge_abr/${this.channelData.channel.legacy_id}`,
    //   buffering: 300,
    //   pause_timeout: 60,
    //   autoplay: this.live,
    //   reconnects: 20,
    //   // offset: 1,
    //   adaptive_bitrate: false,
    //   height: 'parent',
    //   width: 'parent',
    //   initial_resolution: `${this.plyrOptions.quality.default}p`,
    //   key_frame_alignment: true,
    // })
    // this.player = new Plyr('#stream-video', this.plyrOptions);
    // // this.player.on('playing', () => (streamPlaying))
    // this.player.on('pause', () => this.sldpPlayer.pause())
    // this.player.on('play', () => this.sldpPlayer.play())
    // this.player.muted = false
    // this.sldpPlayer = sldpPlayer
    // sldpPlayer.setCallbacks({
    //         // onConnectionStarted: onConnectionStarted,
    //         // onConnectionEstablished:  this.onConnectionEstablished(),
    //         // onPlay: ,
    //         // onPause: onPause,
    //         // onVolumeSet: onVolumeSet,
    //         onError: this.onError,
    //         // onConnectionClosed: onConnectionClosed,
    //         // onChangeRendition: onChangeRendition,
    //         // onChangeRenditionComplete: onChangeRenditionComplete,
    //         // onLatencyAdjustSeek: onLatencyAdjustSeek,
    //         onLowBuffer: this.onLowBuffer
    //       });
    // }    
  },
  async mounted() {
    // Now it's time for the player.
    const channelLookup = await this.channelLookupBySlug(this.channel)
    const channelData = channelLookup
    if (channelData.stream.live == true) {
    await this.loadTransportScripts()
    this.lowLatencyMode = false
    this.updatePlayerTransport();
    }
    document.body.classList.add('channel__page')
    // this.viewCounter = setInterval(async () => {
    // this.viewerCount = await this.getViewercount(window.location.pathname.substring(1).toLowerCase())
    // this.countTerm = (this.viewerCount > 1 ? 'viewers' : 'viewer')
    // }, 5000)
    document.body.classList.remove('channel__page')
  },
  methods: {
    openStream(){
      this.$router.push({path: `/${this.channelData.slug}`})
    },
    setLowLatencyMode(enabled) {
      localStorage.setItem('brime_low_latency_mode', enabled)
      this.lowLatencyMode = enabled
      // console.log('Low latency mode switched to', enabled, ', switching transports.')
      this.updatePlayerTransport()
      // The video element's source will change, so it won't auto play.
      document.querySelector(`#${this.channel}`).play()
    },
    killActiveTransport() {
      if (this.sldpPlayer) this.sldpPlayer.destroy()
      if (this.hls) this.hls.destroy()
      if (this.player) this.player.destroy()
    },
    async loadTransportScripts() {
      // const promise1 = new Promise(resolve => {
      //   const script = document.createElement('script')
      //   script.src = 'https://cdn.jsdelivr.net/npm/hls.js@latest'
      //   script.addEventListener('load', resolve)
      //   document.head.append(script)
      // })
      // const promise2 = new Promise(resolve => {
      //   const script = document.createElement('script')
      //   script.src = 'https://unpkg.com/plyr@3'
      //   script.addEventListener('load', resolve)
      //   document.head.append(script)
      // })
      // await Promise.all([promise1, promise2])
    },
    async updatePlayerTransport() {
      const videoElement = document.querySelector('#'+this.channel)
      this.killActiveTransport()
      // Hide the clip button
      // eslint-disable-next-line
      document
        .querySelector('#clipItButton')
        ?.setAttribute('hidden', true)
      if (this.lowLatencyMode && window.brimePlayer.isMediaSourceSupported() && this.disableLL) { // SLDP check.
        this.brimeLLSuppported = true
        const poster = this.live ? `https://content.brimecdn.com/live/${this.channelData.xid}/thumbnail.jpg` : this.channelData.offlineImageUrl
        this.sldpPlayer = window.brimePlayer.init({
          container: 'stream-player',
          video_element: this.channel,
          stream_url: `wss://${this.streamURL}`,
          splash_screen: this.offline_image,
          autoplay: true,
          offset: 2,
          buffering: 500,
          reconnects: 20,
          adaptive_bitrate: false,
          height: 'parent',
          width: 'parent',
          key_frame_alignment: true,
        })
        const qualityCallback = quality => {
          console.log(quality)
          this.sldpPlayer.changeRendition(`${quality}p`)
        }
        this.initPlyr(qualityCallback)
        this.player.on('pause', () => {
          this.sldpPlayer.pause()
        })
        this.player.on('play', () => {
          this.sldpPlayer.play()
        })
        this.player.poster = this.offline_image
        this.sldpPlayer.setCallbacks({})
      } else {
        this.brimeLLSuppported = false
        const source = `https://${this.streamURL}/playlist.m3u8`
        if (Hls.isSupported()) {
          this.hls = new Hls()
          const hlsLoadPromise = new Promise(resolve => {
            this.hls.on(window.Hls.Events.MANIFEST_PARSED, resolve)
          })
          this.hls.loadSource(source)
          this.hls.attachMedia(videoElement)
          await hlsLoadPromise
          const changeQuality = quality => {
            // console.log(this.hls.levels)
            this.hls.levels.forEach((level, levelIndex) => {
              if (level.height === quality) {
                // console.log('Found quality match with ', quality)
                this.hls.currentLevel = levelIndex
              }
            })
          }
          this.initPlyr(changeQuality)
        } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
          // Very edgecase-y.
          videoElement.src = source
          // Unhide the clip button, since this is most-likely
          // a mobile enviroment that will hide the player and
          // show a platform-specific one.
          // eslint-disable-next-line
          document
            .querySelector('#clipItButton')
            ?.removeAttribute('hidden')
          this.initPlyr(() => {})
        }
      }
    },
    initPlyr(qualityCallback) {
      if (!this.player) {
        this.player = this.$refs.plyr.player
        this.player.muted = true
        console.log(this.player)
        // Finally, click play.
        if (this.live) {
          this.player.play()
        }
      }
    },
    // Chargebee, FeelsBrimeProMan
    async subscribe() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      window.cbInstance.openCheckout({
        hostedPage: () => {
          const messageBody = {
            plan_id: 'tier-1',
            channel_xid: this.channelData.channel.xid,
          }
          return axios
            .post(
              `https://api.brime.tv/v1/subs/generate_checkout`,
              messageBody, config
            )
            .then(response => response.data.hosted_page)
        },
        success: (hostedPageId) => {
          this.isSubscribed = true
        },
      })
    },
    async unfollow(){
      let result = await this.unfollowChannel(this.channelData.channel.xid)
      if (result.ok == true){
        this.following = false
      }
    },
    async follow(){
      let result = await this.followChannel(this.channelData.channel.xid)
      if (result.ok == true){
        this.following = true
      }
    },
    clipit(){
    },
    onConnectionEstablished(){
    },
    onError(err) {
      console.log(err)
    },
    onLowBuffer(){
      console.log('Low Buffer')
    },
    changeQuality(quality){
    if (this.sldpPlayer){
      this.sldpPlayer.changeRendition(quality+'p')
    }
    },
    clearPlaceholder() {
      this.autoCompPlaceholder = ''
    },
    fillAutocomplete() {
      this.textInput += this.autoCompPlaceholder
      this.autoCompPlaceholder = ''
    },
    autoSuggest() {
      const input = this.textInput
      this.autoCompPlaceholder = ''
      const self = this

      if (input.includes('@')) {
        const index = input.indexOf('@')
        if (input[index + 1]) {
          const inputToAutocomplete = input.substring(index + 1)
          let flag = false
          // eslint-disable-next-line no-unused-vars, no-shadow
          this.suggestions.forEach((suggestion, index) => {
            if (flag) return
            if (suggestion.indexOf(inputToAutocomplete) === 0) {
              self.autoCompPlaceholder += suggestion.substring(inputToAutocomplete.length)
              flag = true
            }
          })
        }
      }
    },
    async createConnection() {
    },
  },
  destroyed() {
    // clearInterval( this.viewCounter )
    this.killActiveTransport()
    let plyrSettings = localStorage.getItem('plyr')
    document.title = 'Brime'
  },
}
</script>

<style>
.watchButton{
 margin-top: 0.5rem;
 margin-right: 5%;
 position: relative;
 float: right;
}
video{
  height: 100%;
  min-width: 100%;
}
.btn-outline-primary {
border-top-right-radius: 5px !important;
border-bottom-right-radius: 5px !important;
}
.subscribe {
border-radius: 5px !important;
}
.btn-icon {
border-top-left-radius: 5px !important;
border-bottom-left-radius: 5px !important;
}
.newFollowButton{
border-top-left-radius: 5px !important;
border-bottom-left-radius: 5px !important;
border-top-right-radius: 0px !important;
border-bottom-right-radius: 0px !important;
}
.dropdown-toggle{
margin-left: 10px !important;
padding-left: 2px !important;
padding-right: 2px !important;
background-color: unset !important;
border-color: #7367f0 !important;
border: 5px !important;
}
.actionButtons {
  padding-top: 0.25rem;
  right: calc(21%) !important;
  }
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
 .channelData {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 1rem !important;
  }
  .streamCategory {
    display: block;
  }
    .viewcount {
    font-size: 12px !important;
  }
.row{
  margin-left: 0px !important;
}
.channel__column > .plyr {
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0px 0px
}
.plyr__poster{
  background-size: contain;
}
.plyr__controls{
}
.dark-layout .nav-tabs .nav-item .nav-link.active {
  background-color: unset !important;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
  font-family: 'Roboto', sans-serif;
}
.loadingClip {
  width: 100%;
}
.clipVideo {
  width: 100%;
}
.clipButton {
  margin-top: 5px;
  margin-right: 30px;
}
.subButton {
  margin-top: 5px;
  margin-right: 0px;
}
.followers {
  padding-left: 10px;
  font-size: 14px;
}
.streamTitle {
  padding-top: 5px;
  font-size: 14px;
}
.streamCategory {
  margin-top: -25px;
  margin-left: 55px;
  font-size: 14px;
}


/* Small screens */
@media all and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
.channelData {
    font-size: 14px !important;
    margin-top: 7px !important;
    margin-left: 0.5rem !important;
    padding-bottom: 5px;
  }
  #channelName {
    font-size: 10px !important;
  }
  .tabSection {
    display: none;
  }
  .followCount{
    display: none;
  }
  .actionButtons {
    margin-top: -75px !important;
    right: -15px !important;
    position: fixed;
  }
  #channel {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 2px !important;
  }
  .viewcount {
    font-size: 12px !important;
  }
  .streamCategory {
    margin-top: 7px !important;
    margin-left: 0 !important;
  }
  #followersText {
  display: none;
  }
}
/* Medium and large screens */
@media all and (min-width: 768px) {
  #stream-video {
    /* override other styles to make responsive */
    width: 100% !important;
  }
}
</style>
<style scoped>
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 20px rgba(115, 103, 240, 1);
}

.card img {
  transition: all 0.2s;
}
.card {
  box-shadow: none;
  padding: 0.5rem;
}
.vod__category {
  color: #7367f0 !important;
}
.card-body {
  padding: unset !important;
}
.card {
  background-color: unset !important;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-4px);
}

.card:hover .vod__image {
  opacity: 1;
}

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}

.vod__duration {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.vod__category,
.vod__date {
  font-size: 0.9em;
  color: #fff;
}
.vodSection {
  margin-top: 5vh;
}
#stream-video {
  /* override other styles to make responsive */
  width: 100% !important;
}
.disabled {
  margin-left: 0px !important;
}
.actionButtons {
  margin-top: -50px;
  margin-right: 20px;
}
.creatorMenu {
  margin-top: 10px;
}
#doge-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9001;
}
.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#channel {
  margin-top: 15px;
  top: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
}
#channel img {
  border-radius: 50%;
  vertical-align: middle;
}
.row {
  margin-top: 0;
}
.channel__column {
  flex-wrap: nowrap;
}

#player {
  flex-shrink: 0;
}
.content-body {
  height: 100% !important;
}

.channel__column {
    width: calc(100%);
}

@media screen and (max-width: 768px) {
  .channel__column {
    width: 100%;
    flex-shrink: 0;
  }

  .channel__page {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--headerHeight));
  }

  .content {
    padding: 0px;
  }

  #player {
    max-height: 80%;
  }
}
textarea {
  width: 200px;
  resize: none;
  overflow: hidden;
  font-size: 18px;
  height: 1.1em;
  padding: 2px;
}
</style>
